import logo from './logo.svg';
import Landing from './components/Landing';
import Amenities from './components/Amenities';
import Partner from './components/Partner';
import Contact from './components/Contact'; // Import ReachOut component
import Request from './components/Request'; // Import Request component
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import './App.scss';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/amenities" element={<Amenities />} />
          <Route path="/partner" element={<Partner />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/request" element={<Request />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;