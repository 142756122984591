import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import amIMG1 from "./assets/images/amIMG1.jpeg"
import amIMG2 from "./assets/images/wifi.jpeg"
import amIMG3 from "./assets/images/linens.jpeg"
import amIMG4 from "./assets/images/toiletries.jpeg"
import amIMG5 from "./assets/images/worksp.jpeg"
import amIMG6 from "./assets/images/stockedkit.png"
import amIMG7 from "./assets/images/unitdry.jpeg"
import amIMG8 from "./assets/images/cleaned.jpeg"
import amIMG9 from "./assets/images/heinz-pic.jpeg"
import amIMG10 from "./assets/images/lengthstay.jpeg"
import Intersect from "./InterSectCheck";



function Amenities() {

    const handleIntersect1 = () => {
        console.log('Target element is in the frame!');
        const gc1 = document.querySelector('.gc1');
        gc1.classList.add('active');
    };
    const handleIntersect2 = () => {
        console.log('Target element is in the frame!');
        const gc2 = document.querySelector('.gc2');
        gc2.classList.add('active');
    };
    const handleIntersect3 = () => {
        console.log('Target element is in the frame!');
        const gc3 = document.querySelector('.gc3');
        gc3.classList.add('active');
    };
    const handleIntersect4 = () => {
        console.log('Target element is in the frame!');
        const gc4 = document.querySelector('.gc4');
        gc4.classList.add('active');
    };
    const handleIntersect5 = () => {
        console.log('Target element is in the frame!');
        const gc5 = document.querySelector('.gc5');
        gc5.classList.add('active');
    };
    const handleIntersect6 = () => {
        console.log('Target element is in the frame!');
        const gc6 = document.querySelector('.gc6');
        gc6.classList.add('active');
    };
    const handleIntersect7 = () => {
        console.log('Target element is in the frame!');
        const gc7 = document.querySelector('.gc7');
        gc7.classList.add('active');
    };
    const handleIntersect8 = () => {
        console.log('Target element is in the frame!');
        const gc8 = document.querySelector('.gc8');
        gc8.classList.add('active');
    };
    const handleIntersect9 = () => {
        console.log('Target element is in the frame!');
        const gc9 = document.querySelector('.gc9');
        gc9.classList.add('active');
    };
    const handleIntersect10 = () => {
        console.log('Target element is in the frame!');
        const gc10 = document.querySelector('.gc10');
        gc10.classList.add('active');
    };

    
    return(
        <div className="amenities-component">
            {/* amenities gallery section */}
            <h1> Rivers Amenities </h1>
            <div className="amenities-gallery">
                <div className="gallery-cont gc1">
                    <img src={amIMG9} />
                    <h3> Central Location</h3>
                    {/*  */}
          
                    <div className="intersect">
                        <Intersect onIntersect={handleIntersect1} threshold={1} rootMargin="50px" />
                    </div>
                </div>
               
                <div className="gallery-cont gc2">
                <img src={amIMG1} />
                    <h3>Self Check-in</h3>
                   
                    <div className="intersect">
                        <Intersect onIntersect={handleIntersect2} threshold={1} rootMargin="50px" />
                    </div>
                </div>
               
                <div className="gallery-cont gc3">
                <img src={amIMG2} />
                    <h3>Complementary High-Speed Wifi</h3>
         
                    <div className="intersect">
                        <Intersect onIntersect={handleIntersect3} threshold={1} rootMargin="50px" />
                    </div>
                </div>
               
                <div className="gallery-cont gc4">
                <img src={amIMG3} />
                    <h3> Fresh Linens</h3>
           
                    <div className="intersect">
                        <Intersect onIntersect={handleIntersect4} threshold={1} rootMargin="50px" />
                    </div>
                </div>
               
                <div className="gallery-cont gc5">
                <img src={amIMG4} />
                    <h3> Hotel-Style Toiletries</h3>
          
                    <div className="intersect">
                        <Intersect onIntersect={handleIntersect5} threshold={1} rootMargin="50px" />
                    </div>
                </div>
               
                <div className="gallery-cont gc6">
                <img src={amIMG5} />
                    <h3> Dedicated Work Space </h3>
           
                    <div className="intersect">
                        <Intersect onIntersect={handleIntersect6} threshold={1} rootMargin="50px" />
                    </div>
                </div>
               
                <div className="gallery-cont gc7">
                <img src={amIMG6} />
                    <h3> Kitchen Stocked With Essentials </h3>
   
                    <div className="intersect">
                        <Intersect onIntersect={handleIntersect7} threshold={1} rootMargin="50px" />
                    </div>
                </div>
               
                <div className="gallery-cont gc8">
                <img src={amIMG7} />
                    <h3>In-Unit Washer & Dryer</h3>
          
                    <div className="intersect">
                        <Intersect onIntersect={handleIntersect8} threshold={1} rootMargin="50px" />
                    </div>
                </div>
                <div className="gallery-cont gc9">
                <img src={amIMG8} />
                    <h3>Professionally Cleaned </h3>
            
                    {/*  */}
                    <div className="intersect">
                        <Intersect onIntersect={handleIntersect9} threshold={1} rootMargin="50px" />
                    </div>
                </div>
                <div className="gallery-cont gc10">
                    <img src={amIMG10} />
                    <h3> Flexible Length Stays </h3>
                    <div className="intersect">
                        <Intersect onIntersect={handleIntersect10} threshold={1} rootMargin="50px" />
                    </div>
                </div>
               
            </div>

        </div>
    )
}

export default Amenities;