import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
// import vase from "./assets/images/plant-vase.jpeg"
import vase from "./assets/images/tp6.jpeg"
import sink from "./assets/images/modern-sink.jpeg"
import bath from "./assets/images/bathrm.jpeg"
import Intersect from "./InterSectCheck";


function Partner() {
    const handleIntersect1 = () => {
        console.log('Target element is in the frame!');
        const faq = document.querySelector('.faq-section');
        faq.classList.add('active');
    };

    return(
        <div className="Partner-section">
            <div className="about-intro">
                <img src={vase} />
                <h1>About Rivers Oasis</h1>
                <h3>Learn more about who we are, what we offer, and how we help.</h3>
            </div>
            <div className="about-summary">
                <p>Rivers Oasis transforms Short Term Rentals, bringing our guests a quality travel experience. We offer apartments, lofts, and vacation homes to professional travelers in the most visited neighborhoods. Our stays provide hotel-like amenities, such as travel-size toiletries, towels, & premium linen for every stay, along with all the advantages of a home, like spacious kitchens and comfortable living spaces.</p>
                <p>Our guests find comfort, quality, and safety at every stay. Our local teams at each location work hard to maintain a consistent quality experience for each guest. Rivers Oasis is available 24/7 via text or phone call to answer any questions, concerns, or accommodations.</p>
                <p>Are you looking to stay with us for a month or longer? We welcome you home. Rivers Oasis offers 30+ night stays to accommodate the corporate/business traveler, long-haul vacationer, and digital nomad. Located in Saint Louis, Rivers Oasis offers travelers extended stay accommodations with our fully-furnished apartment suites that seamlessly integrate work and play while providing all the comforts of home.</p>
            </div>

            {/* FAQ section graphic */}
            <div className="faq-section">
                 <div className="faq-imgm">
                    <img src={sink} />
                </div>
                <div className="faq-info-section">
                    <div className="faq-info">
                        <h1> FREQUENTLY ASKED QUESTIONS</h1>

                        {/* question */}
                        <h2>Does Rivers Oasis have a cleaning team?</h2>
                        <h3>After each stay, our professional cleaning team will inspect and clean each unit. If a guest stays for an extended period, we clean the apartment weekly upon request.</h3>
                        {/* question */}
                        <h2>Does Rivers Oasis screen their guests?</h2>
                        <h3>Yes. No matter the length of stay, we require identity verification and screening before a reservation.</h3>
                          {/* question */}
                        <h2>Who are Rivers Oasis guests, and how long are their typical stays?</h2>
                        <h3>Rivers Oasis guests include those traveling for leisure, business, or corporate stays. Our stays range from one night to six months, with an average stay of three nights.</h3>
                        {/* question */}
                        <h2>How does Rivers Oasis market its suites?</h2>
                        <h3>We advertise our suites here on our direct booking website. We also market them on other third-party short-term rental sites.</h3>
                      

                    </div>
                </div>
                <div className="faq-img">
                    <img src={sink} />
                </div>
                <div className="intersect intersect-faq">
                    <Intersect onIntersect={handleIntersect1} threshold={1} rootMargin="50px" />
                </div>
            </div>

            {/* bottom graphic (IMAGE BANNER) */}
            <div className="img-banner">
                <img src={bath} />
                <h1>We look forward to your stay.</h1>
            </div>

            {/* <Footer /> */}
        </div>
    )
}

export default Partner;