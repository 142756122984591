import React , {useEffect, useRef} from "react";

const Intersect = ({ onIntersect, threshold = 1, rootMargin = '0px' }) => {
    const targetRef = useRef(null);
  
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
              console.log('Intersection ratio:', entry.intersectionRatio);
              if (entry.isIntersecting) {
                onIntersect();
                observer.unobserve(entry.target);
              }
            });
          }, { threshold, rootMargin });
      
          if (targetRef.current) {
            observer.observe(targetRef.current);
          }
      
          return () => {
            if (targetRef.current) {
              observer.unobserve(targetRef.current);
            }
          };
        }, [onIntersect, threshold, rootMargin]);
      

    return <div ref={targetRef} />;

}

export default Intersect;