import React from "react";
import ig from "./assets/images/white-ig.png";
import twitter from "./assets/images/white-tw.png";
import fb from "./assets/images/white-fb.png";

function Footer() {
    return(
        <div className="Footer">
             <h1>Rivers Oasis</h1>

            <div className="footer-social-links">
            <div className="link">
                    <a href="ig">
                        <img src={ig} />
                    </a>
                </div>
                <div className="link">
                    <a href="facebook">
                        <img src={fb} />
                    </a>
                </div>
                <div className="link">
                    <a href="twitter">
                        <img src={twitter} />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Footer;