import React, { useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import NavBar from "./NavBar";
import Footer from "./Footer";

function Contact() {
  useEffect(() => {
    const loadMapScript = () => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&callback=initMap`;
      script.async = true;
      document.body.appendChild(script);
    };

    loadMapScript();
  }, []);

  const mapStyles = {
    height: "500px",
    width: "100%",
  };

//   const defaultCenter = {
//     lat: 40.437, // Latitude of Point Park
//     lng: -80.013, // Longitude of Point Park
//   };
const defaultCenter = {
    lat: -25.344,
    lng: 131.031,
  };
  return (
    <div className="ReachOut-component">
      <div className="reachout-content">
        <div className="contact-info">
          <h2>Rivers Oasis</h2>
          <h3>Based in Pittsburgh, Pennsylvania</h3>

          <h3 className="bold-font">Phone</h3>
          <h3>(724) 678-4394</h3>

          <h3 className="bold-font">Front Desk</h3>
          <h3>Info@RiversOasis.com</h3>

          <h3 className="bold-font">Inquires</h3>
        
          <h3>ShaneBowman@RiversOasis.com</h3>
          <h3>GeraldBowmnan@RiversOasis.com</h3>
          <h4>
            Would you like to stay with us or are you interested in partnering with our company? Contact us today!
          </h4>
        </div>

        <div className="map-section">
          <div className="map">
            <LoadScript googleMapsApiKey="AIzaSyA93B_SL3paKaGXeXkqDWezTmjdSOelD60">
              <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={4}
                center={defaultCenter}
                mapId="DEMO_MAP_ID"
              >
                <Marker position={defaultCenter} title="Uluru" />
              </GoogleMap>
            </LoadScript>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;