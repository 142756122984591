// import React from "react";
// import Footer from "./Footer";
// import NavBar from "./NavBar";
// import chair from "./assets/images/last-gold.jpeg"

// function Request() {
//     return(
//         <div className="Request-component">
//             <div className="request-intro">
//                     <img src={chair} />
//                     <h1>Request Your Stay Today</h1>
//                     <h3>Our team usually responds to all inquires within 24 hours.</h3>
//                 </div>
                
//                 {/* //TODO build request form  */}
                
//                 {/* Required guest info:
//                     - Name 
//                     - check in date
//                     - check out date
//                     - # of Guests 
//                     - phone number
//                     - email 
//                     - ???
//                 */}

//             {/* bottom graphic (IMAGE BANNER) */}
//             <div className="request-img-banner">
//                     <img src="" />
//                     <h1>We look forward to your stay.</h1>
//                     <h2> Reach out to us with your information and which stay you’re interested in. </h2>
//                 </div>
//         </div>
//     )
// }

// export default Request;
import React, { useState } from "react";
import chair from "./assets/images/last-gold.jpeg";
import bed from "./assets/images/wt-bed.jpeg";

function Request() {
  const [name, setName] = useState("");
  const [checkInDate, setCheckInDate] = useState("");
  const [checkOutDate, setCheckOutDate] = useState("");
  const [numOfGuests, setNumOfGuests] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleCheckInDateChange = (e) => {
    setCheckInDate(e.target.value);
  };

  const handleCheckOutDateChange = (e) => {
    setCheckOutDate(e.target.value);
  };

  const handleNumOfGuestsChange = (e) => {
    setNumOfGuests(parseInt(e.target.value));
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform form submission or data handling here
    console.log(
      "Form submitted:",
      name,
      checkInDate,
      checkOutDate,
      numOfGuests,
      phoneNumber,
      email
    );
    // Reset form fields
    setName("");
    setCheckInDate("");
    setCheckOutDate("");
    setNumOfGuests(1);
    setPhoneNumber("");
    setEmail("");
  };

  return (
    <div className="Request-component">
      <div className="request-intro">
        <img src={chair} alt="Chair" />
        <h1>Request Your Stay Today</h1>
        <h3>Our team usually responds to all inquiries within 24 hours.</h3>
      </div>


      <h1 className="form-title"> Schedule Your Stay Now! </h1>
      {/* Request form */}
      <form className="request-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={handleNameChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="checkInDate">Check-In Date:</label>
          <input
            type="date"
            id="checkInDate"
            value={checkInDate}
            onChange={handleCheckInDateChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="checkOutDate">Check-Out Date:</label>
          <input
            type="date"
            id="checkOutDate"
            value={checkOutDate}
            onChange={handleCheckOutDateChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="numOfGuests">Number of Guests:</label>
          <input
            type="number"
            id="numOfGuests"
            value={numOfGuests}
            onChange={handleNumOfGuestsChange}
            min="1"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="phoneNumber">Phone Number:</label>
          <input
            type="tel"
            id="phoneNumber"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            required
          />
        </div>

        <button type="submit"> Request Your Stay </button>
      </form>

      {/* Bottom graphic (IMAGE BANNER) */}
      <div className="request-img-banner">
        <img src={bed} alt="Image Banner" />
        <h1>We look forward to your stay.</h1>
        <h2>Reach out to us with your information and which stay you're interested in.</h2>
      </div>
    </div>
  );
}

export default Request;