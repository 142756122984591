import React, { useState, useEffect } from "react";
import ig from "./assets/images/white-ig.png";
import twitter from "./assets/images/white-tw.png";
import fb from "./assets/images/white-fb.png";
// import openSea from "./content/imgs/image_1.png";
// import twitter from "./content/imgs/blackTW.png";

function NavBar() {

      const [isActive, setIsActive] = useState(false);

      useEffect(() => {
        if (isActive) {
          document.body.style.overflow = "hidden"; // Disable scrolling
        } else {
          document.body.style.overflow = ""; // Enable scrolling
        }
      }, [isActive]);
    
      const toggleHamburger = () => {
        setIsActive(!isActive);
      };

    return(
        <div className="navbar">
            <div className="WordMark">
                <h1>  <a href="./"> Rivers Oasis </a> </h1>
            </div>

            <div className="nav-right-section">
                <ul>
                    <li>
                        <a href="./"> 
                        Home
                        </a>
                    </li>
                    <li>
                        <a href="./Amenities"> 
                        Amenities
                        </a>
                    </li>
                    <li>
                        <a href="./Partner"> 
                        About
                        </a>
                    </li>
                    <li>
                        <a href="./Contact"> 
                        Contact
                        </a>
                    </li>
                </ul>
            </div>

            <div className="social-links">
                <div className="link">
                    <a href="">
                        <img src={ig} />
                    </a>
                </div>
                <div className="link">
                    <a href="">
                        <img src={fb} />
                    </a>
                </div>
                <div className="link">
                    <a href="">
                        <img src={twitter} />
                    </a>
                </div>
            </div>

            <div className="request-btn">
                <a href="./Request"> Request a Stay </a>
            </div>

                <div onClick={toggleHamburger} className={`hamburger ${isActive ? "active" : ""}`}>
                    <div className={`bar ${isActive ? "active" : ""}`}></div>
                    <div className={`bar ${isActive ? "active" : ""}`}></div>
                </div>

                <div className={`mediaNavPopUp ${isActive ? "active" : ""}`}>
        <div className="mediaNavContent">
          <ul>
            <li>
              <a href="./">Home</a>
            </li>
            <li>
              <a href="./Partner">About</a>
            </li>
          
            <li>
              <a href="./Contact">Contact</a>
            </li>
            <li>
              <a href="./Amenities">Amenities</a>
            </li>
         
          </ul>
        </div>


        <div className="msocial-links">
                <div className="mlink">
                    <a href="">
                        <img src={ig} />
                    </a>
                </div>
                <div className="mlink">
                    <a href="">
                        <img src={fb} />
                    </a>
                </div>
                <div className="mlink">
                    <a href="">
                        <img src={twitter} />
                    </a>
                </div>
            </div>
        <a href="./Request">
          <button>Request a Stay</button>
        </a>
      </div>

        </div>
    )
}

export default NavBar;