import React, { useEffect, useRef } from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import kitchen from "./assets/images/kitchen-pic.jpeg"
import livingRoom from "./assets/images/fury-living-room.jpeg"
// import livingRoom from "./assets/images/blue-living-room.jpeg"
// import livingRoom from "./assets/images/luxury-img-lvng-room.webp"
// import livingRoom from "./assets/images/white-living-room.jpeg"
import pncPark from "./assets/images/point-pic.jpeg"
// import amenities from "./assets/images/indoor-swimming-pool1.jpeg"
import amenities from "./assets/images/indoor-pool-long.png"
import Intersect from "./InterSectCheck";



function Landing() {

    const handleIntersect1 = () => {
        console.log('Target element is in the frame!');
        const comfort = document.querySelector('.Comfort-section');
        comfort.classList.add('active');
    };
    const handleIntersect2 = () => {
        console.log('Target element is in the frame!');
        const comfort = document.querySelector('.City-section');
        comfort.classList.add('active');
    };
    const handleIntersect3 = () => {
        console.log('Target element is in the frame!');
        const comfort = document.querySelector('.Amenities-section');
        comfort.classList.add('active');
    };

    return (
        <div className="Landing">
        {/* <NavBar /> */}
        {/* //landing content */}
            <div className="Landing-content">
                <div className="title-div">
          
                    <h1>Stay at our Luxury Oasis.</h1>
                    <h1> Your Riverside Getaway Awaits.</h1>
                </div>
                <div className="landing-image">
                    <img src={kitchen} atl="" /> 
                </div>
            </div>

            {/* Landing scroll content */}

            
                {/* Comfort section */}
                <div className="Comfort-section">
                    <div className="comfort-img">
                        <img src={livingRoom} />
                    </div>
                    <div className="comfort-info">
                        <h2>When it comes to comfort, the choice is yours.</h2>
                        <p>Designed to be a  <span>sanctuary</span> in the middle of the city. From spacious lofts to beaming homes, Rivers Oasis leaves the choice in your hands!</p>
                    </div>
                    <div className="intersect intersect-home">
                        <Intersect onIntersect={handleIntersect1} threshold={1} rootMargin="50px" />
                    </div>
                </div>
            

                {/* city section */}
                <div  className="City-section">
                <div className="city-imgm">
                        <img src={pncPark} />
                    </div> 
                    <div className="city-info">
                        <h2>Across Pittsburgh</h2>
                        <p>Stay in Prime Pittsburgh Locations:</p>
                        <p>Downtown Pittsburgh</p>
                        <p>North Shore</p>
                        <p>Mt. Washington</p>
                        <p>Southside Flats</p>
                        <p>Explore a day in the life of a community of artists, innovators and sports fans. </p>

                    </div>
                    <div className="city-img">
                        <img src={pncPark} />
                    </div> 
                    <div className="intersect intersect-city">
                        <Intersect onIntersect={handleIntersect2} threshold={1} rootMargin="50px" />
                    </div>
                   
                </div>


            {/* amenities section */}
            <div className="Amenities-section">
                <div className="amenities-img">
                    <img src={amenities} />
                </div>
                <div className="amenities-content-box">
                    <h1>Rivers Amenities</h1>
                    <h3>Quality Guest Experience</h3>
                   <a href="./Amenities"> <button>Learn More</button> </a> 
                </div>
                <div className="intersect intersectAmen">
                    <Intersect onIntersect={handleIntersect3} threshold={1} rootMargin="50px" />
                </div>
            </div>
            
            

 
        </div>
    )
}

export default Landing;